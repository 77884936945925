<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card :loading="loading" flat>
					<v-card-title>
						{{
							view
								? "Visualizar processo"
								: id
								? "Editar processo"
								: "Cadastrar processo"
						}}
					</v-card-title>
					<v-col class="pl-10 pr-10 pb-5" cols="12" md="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODGRP"
									:items="groups"
									item-text="DESGRP"
									item-value="CODGRP"
									label="Grupo"
									class="required"
									clearable
									:loading="loadingGroup"
									@change="getProgramas()"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.GERCOPARE"
									:items="corporateAreas"
									item-text="DESAREA"
									item-value="GERCOPARE"
									label="Área Corporativa"
									:loading="loadingCorporateArea"
									clearable
									@change="
										updateCorporateArea();
										getProgramas();
									"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODEMP"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:disabled="disableCompany"
									:loading="loadingCompany"
									clearable
									@change="
										getUnits(true);
										getProgramas();
									"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODUNI"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:disabled="disableUnit"
									:loading="loadingUnit"
									clearable
									@change="
										getAreas(true);
										getProgramas();
									"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODARE"
									:items="areas"
									item-text="DESARE"
									item-value="CODARE"
									label="Área"
									:disabled="disableArea"
									clearable
									:loading="loadingArea"
									@change="getProgramas()"
									no-data-text="Nenhuma área encontrada."
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.CODTIP"
									:items="types"
									item-text="DESTIP"
									item-value="CODTIP"
									label="Tipo"
									:disabled="disableType"
									clearable
									:loading="loadingType"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="process.programas"
									:items="programas"
									item-text="TITPROG"
									item-value="GERPROG"
									label="Programas"
									:disabled="disableProgramas"
									clearable
									:loading="loadingPrograma"
									no-data-text="Nenhum programa encontrado."
									multiple
								></v-select>
							</v-col>
							<v-col cols="6" md="8">
								<v-text-field
									v-model="process.TITPRO"
									label="Título"
									class="required"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="12">
								<v-textarea
									:counter="true"
									v-model="process.DESPRO"
									label="Descrição"
									class="required"
								></v-textarea>
							</v-col>
							<v-col cols="3" md="2">
								<v-text-field
									v-model="process.CODIGO"
									label="Código"
									disabled
								></v-text-field>
							</v-col>
							<v-col cols="3" md="2">
								<v-text-field
									type="number"
									v-model="process.VERSAO"
									label="Versão"
									class="required"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<v-file-input
									id="fileUploader"
									@change="onFileSelect"
									type="file"
									:rules="fileRules"
									accept=".pdf, .doc, .docx"
									placeholder="Clique aqui para selecionar um arquivo pdf ou doc"
									prepend-icon="mdi-paperclip"
									show-size
									label="Arquivo (.PDF .DOC)"
								></v-file-input>
								<v-row v-if="process.FILE">
									<v-col cols="1">
										<v-icon class="mr-2" @click="download(item)"
											>mdi-paperclip</v-icon
										>
									</v-col>
									<v-col cols="10">
										{{ process.FILE_NAME }}
									</v-col>
									<v-col cols="1">
										<v-icon
											class="mr-2"
											@click="removeDownload()"
											style="color: red"
											>mdi-delete</v-icon
										>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row class="mt-5">
							<v-container>
								<v-btn
									color="primary"
									depressed
									tile
									class="mr-4"
									@click="save"
									:disabled="loading"
									v-show="!view"
									>Salvar</v-btn
								>
								<v-btn
									color="primary"
									text
									class="mr-4"
									@click="$router.push('/process/list')"
									:disabled="loading"
									>Voltar</v-btn
								>
							</v-container>
						</v-row>
					</v-col>
				</v-card>
			</v-col>
		</v-row>
		<template>
			<Dialog ref="confirm" />
		</template>
	</v-container>
</template>

<script>
import CompanyGroupService from "../../app/Services/CompanyGroupService";

import Dialog from "../misc/Dialog";
import ProcessService from "../../app/Services/ProcessService";
import CompanyService from "../../app/Services/CompanyService";
import AreaService from "../../app/Services/AreaService";
import CorporateAreaService from "../../app/Services/CorporateAreaService";
import UnitService from "../../app/Services/UnitService";
import UserService from "../../app/Services/UserService";
import axios from "../../config/service/standard";
import ProgramService from "../../app/Services/ProgramService";
import TypeService from "../../app/Services/TypeService";

const companyGroupService = CompanyGroupService.build();
const processService = ProcessService.build();
const companyService = CompanyService.build();
const areaService = AreaService.build();
const corporateAreaService = CorporateAreaService.build();
const unitService = UnitService.build();
const userService = UserService.build();
const typeService = TypeService.build();

export default {
	name: "ProcessForm",
	components: { Dialog },
	data: () => ({
		id: null,
		fileRules: [],
		view: false,
		groups: [],
		companies: [],
		areas: [],
		corporateAreas: [],
		units: [],
		status: [
			{ id: "A", description: "Aberto" },
			{ id: "F", description: "Fechado" },
		],
		process: {
			GERPRO: 0,
			CODGRP: 0,
			CODEMP: 0,
			CODUNI: 0,
			CODARE: 0,
			TITPRO: "",
			DESPRO: "",
			STATUS: 1,
			VERSAO: 1,
			CODUSU: "",
			GERCOPARE: 0,
			USUCAD: "",
			FILE_NAME: "",
			FILE: "",
			CREATED_AT: "",
			UPDATED_AT: "",
			CODIGO: "",
		},
		loadingGroup: false,
		loading: false,
		loadingCompany: false,
		loadingUnit: false,
		loadingArea: false,
		loadingPrograma: false,
		loadingCorporateArea: false,
		disableCompany: false,
		disableUnit: false,
		disableArea: false,
		disableProgramas: false,
		fileBase64: "",
		programas: [],
		programasModel: [],
		types: [],
		loadingType: false,
		disableType: false,
	}),
	mounted() {
		const { id, view } = this.$route.params;

		if (id) {
			this.id = id;
		}

		if (view) {
			this.view = view;
		}

		this.fetch();
	},
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		save() {
			if (!this.process.CODGRP) {
				this.toast("warning", "Informe o grupo do processo para continuar");
				return;
			}

			if (!this.process.TITPRO) {
				this.toast("warning", "Informe o título do processo para continuar");
				return;
			}

			if (!this.process.DESPRO) {
				this.toast("warning", "Informe a descrição do processo para continuar");
				return;
			}

			if (!this.process.VERSAO) {
				this.toast("warning", "Informe a versão do processo para continuar");
				return;
			}

			this.loading = true;

			if (this.fileBase64 != "") {
				this.process.FILE = this.fileBase64;
			}

			const rest = this.id
				? processService.update({ id: this.id, ...this.process })
				: processService.create({ ...this.process });

			rest
				.then((response) => {
					if (!response) {
						return;
					}

					this.toast(
						"success",
						this.id
							? "Processo atualizada com sucesso!"
							: "Processo adicionada com sucesso!"
					);
					this.loading = false;

					if (!this.isInModal) {
						this.$router.push("/process/list");
					} else {
						this.$emit("saved", true);
					}
				})
				.catch((res) => {
					if (res.response.data) {
						const data = JSON.parse(res.response.data);

						this.toast("erro", data.message);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async getPrograma() {
			this.loading = true;

			return processService.read({ id: this.id }).then((response) => {
				this.process = response;
				this.loading = false;
			});
		},
		getGroup() {
			this.loadingGroup = true;

			companyGroupService.search("").then((response) => {
				this.groups = response;
				this.loadingGroup = false;
			});
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;
				this.loadingCompany = false;
			});
		},
		getUnits(change) {
			const search = {};

			this.units = [];
			this.areas = [];
			this.loadingUnit = true;

			if (change) {
				this.process.CODUNI = "";
				this.process.CODARE = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			return unitService.search(search).then((response) => {
				this.units = response;
				this.loadingUnit = false;
			});
		},
		getAreas(change) {
			const search = {};

			this.areas = [];
			this.loadingArea = true;

			if (change) {
				this.process.CODARE = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.unit = this.process.CODUNI;
			}

			areaService.search(search).then((response) => {
				this.areas = response;
				this.loadingArea = false;
			});
		},
		getCorporateArea(change) {
			const search = {};

			this.corporateAreas = [];
			this.loadingCorporateArea = true;

			if (change) {
				this.process.CODARE = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.unit = this.process.CODUNI;
			}

			corporateAreaService.search(search).then((response) => {
				this.corporateAreas = response;
				this.loadingCorporateArea = false;
			});
		},
		async getProgramas() {
			let search = {
				CODGRP: 0,
				CODARE: 0,
				CODEMP: 0,
				CODUNI: 0,
				GERCOPARE: 0,
			};

			if (this.process.CODGRP) {
				search.CODGRP = this.process.CODGRP;
			}

			if (this.process.CODARE) {
				search.CODARE = this.process.CODARE;
			}

			if (this.process.CODEMP) {
				search.CODEMP = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.CODUNI = this.process.CODUNI;
			}

			if (this.process.GERCOPARE) {
				search.GERCOPARE = this.process.GERCOPARE;
			}

			this.programas = [];
			this.loadingPrograma = true;
			const response = await ProgramService.build().search(search);
			this.programas = response;
			this.loadingPrograma = false;
		},
		getUsers() {
			this.loadingUser = true;
			return userService.usersByKey().then((response) => {
				this.users = response;
				this.loadingUser = false;
			});
		},
		updateCorporateArea() {
			if (parseInt(this.process.GERCOPARE) > 0) {
				this.process.CODEMP = 0;
				this.process.CODUNI = 0;
				this.process.CODARE = 0;

				this.disableCompany = true;
				this.disableUnit = true;
				this.disableArea = true;
				this.disableProgramas = false;
			} else {
				this.disableCompany = false;
				this.disableUnit = false;
				this.disableArea = false;
				this.disableProgramas = false;
			}
		},
		fetch() {
			const funcs = [];

			if (this.id) {
				funcs.push(this.getPrograma());
			}

			funcs.push(this.getGroup());
			funcs.push(this.getCompanies());
			funcs.push(this.getUsers());
			funcs.push(this.getCorporateArea());
			funcs.push(this.getTypes());

			Promise.all(funcs)
				.then(() => {
					if (this.id) {
						this.getUnits(false);
						this.getAreas();
						this.updateCorporateArea();
					}

					this.getProgramas();
				})
				.finally(() => {});
		},
		onFileSelect(fileTmp) {
			if (fileTmp == null) return;

			this.process.FILE_NAME = fileTmp.name;
			this.process.FILE_TYPE = fileTmp.type;

			this.toBase64(fileTmp).then((base64) => {
				this.fileBase64 = base64;
			});
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
				reader.readAsDataURL(file);
			});
		},
		download() {
			this.$root.$Loading.show("Fazendo download...");

			axios
				.get("/api/process/download", {
					contentType: "application/json",
					responseType: "application/json",
					params: {
						id: this.process.GERPRO,
					},
				})
				.then((response) => {
					const data = JSON.parse(response.data);
					const file = Buffer.from(data.file, "base64");
					let blob = new Blob([file], {
						type: data.type,
					});

					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = data.name;
					link.click();
				})
				.finally(() => {
					this.$root.$Loading.hide();
				});
		},
		async removeDownload() {
			await this.$refs.confirm
				.open("Atenção", "Deseja mesmo excluir?")
				.then(() => {
					this.$root.$Loading.show("Removendo arquivo...");

					axios
						.post("/api/process/removeDownload", {
							id: this.process.GERPRO,
						})
						.then(() => {
							this.toast("success", "Arquivo removido com sucesso!");
							this.process.FILE = null;
						})
						.finally(() => {
							this.$root.$Loading.hide();
						});
				});
		},
		getTypes(change) {
			const search = {};

			this.types = [];
			this.loadingType = true;

			if (change) {
				this.process.CODTIP = "";
			}

			if (this.process.CODEMP) {
				search.company = this.process.CODEMP;
			}

			if (this.process.CODUNI) {
				search.unit = this.process.CODUNI;
			}

			typeService.search(search).then((response) => {
				this.types = response.data;
				this.loadingType = false;
			});
		},
	},
};
</script>
